import { TPlatform, IMerchantInfo, TAppEnv } from '@/models'
import {
  IAdminPaymentOptions,
  IEventBus,
  IImageUpload,
  IPaymentFees,
  IPaymentOptions,
  IPaymentServiceConfigurations,
  IPaymentServices,
  IPaymentTerminals,
  ITerminalListService,
  IAllowedOrigins,
} from '@/services/types'

export type TFactory<T, Config> = (config?: Config) => T

export const AppModes = {
  ADMIN: 'admin',
  MERCHANT: 'merchant',
}
const appModes = ['pos', 'ecom']
export type TAppMode = (typeof appModes)[number]

interface IAppConfigInitParams {
  merchantId?: string
  merchant?: IMerchantInfo
  authToken?: string | (() => Promise<string>)
  platform?: TPlatform
  mode?: TAppMode
  isMultiMerchant?: boolean
  router: {
    navigate: (path: string) => void
  }
  services?: {
    imageUploadService?: null | IImageUpload
    terminalListService?: null | ITerminalListService
    allowedDomainsListService?: null | IAllowedOrigins
  }
}

export interface IAppConfig extends IAppConfigInitParams {
  merchant: IMerchantInfo
  baseURL: string
  init: (params: IAppConfigInitParams) => void
  initialized: boolean

  factories: {
    paymentServiceConfigurations: TFactory<IPaymentServiceConfigurations, IAppConfig>
    paymentOptions: TFactory<IPaymentOptions, IAppConfig>
    adminPaymentOptions: TFactory<IAdminPaymentOptions, IAppConfig>
    paymentServiceTerminals: TFactory<IPaymentTerminals, IAppConfig>
    paymentServiceFees: TFactory<IPaymentFees, IAppConfig>
    paymentServices: TFactory<IPaymentServices, IAppConfig>
    imageUpload: TFactory<IImageUpload, IAppConfig>
    allowedOrigins: TFactory<IAllowedOrigins, IAppConfig>
    eventBus: TFactory<IEventBus, IAppConfig>
  }

  getEnvironment: () => TAppEnv
}
