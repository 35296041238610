import { useState } from 'react'
import {
  EPaymentConfigurationStatus,
  incrementOverrides,
  IPaymentServiceConfiguration,
} from '@/models'
import { useConfigurationApproval } from '@/hooks/useConfigurationApproval'
import { ListItem, ListItemText, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { PaymentServiceApprovalStatusBadge } from '@/components/PaymentServiceApprovalStatusBadge'
import { ConfirmationDialog } from '@/components/ConfirmationDialog'
import { makeStyles } from '@/theme'
import { IncrementOverride } from '@/components/ConfigurationApprovalsDrawer/IncrementOverride'

interface IConfigurationApprovalRowProps {
  config: IPaymentServiceConfiguration
  hasIncrementOverrides: boolean
  refresh: () => void
}

export function ConfigurationApprovalRow(props: IConfigurationApprovalRowProps) {
  const [pendingApprovalStatus, setPendingApprovalStatus] =
    useState<EPaymentConfigurationStatus | null>(null)

  function closeApprovalStatusConfirmation() {
    setPendingApprovalStatus(null)
  }

  async function confirmApprovalStatusAction() {
    if (pendingApprovalStatus) await updateConfigurationApprovalStatus(pendingApprovalStatus)
    closeApprovalStatusConfirmation()
  }

  const {
    actions: { updateConfigurationApprovalStatus, updateIncrementOverride },
    state: { loading },
  } = useConfigurationApproval(props.config)

  const { cx, classes } = useStyles()
  const { cx: dynamicCx, classes: dynamicClasses } = useDynamicStyles({
    isMiddleAction: props.hasIncrementOverrides,
  })

  const handleStatusChange = (event, newStatus: EPaymentConfigurationStatus | null) => {
    setPendingApprovalStatus(newStatus ?? EPaymentConfigurationStatus.PENDING_APPROVAL)
  }

  const control = {
    value: props.config.status,
    onChange: handleStatusChange,
    exclusive: true,
  }

  const handleIncrementChange = (increment: number | null) =>
    updateIncrementOverride(props.config, increment)

  return (
    <ListItem
      className={cx(classes.listItem)}
      secondaryAction={
        <div className={cx(classes.actions)}>
          {props.hasIncrementOverrides && (
            <IncrementOverride
              incrementOptionValues={incrementOverrides}
              initialIncrement={props.config?.increment}
              handleIncrementChange={handleIncrementChange}
              refresh={props.refresh}
            />
          )}
          <ToggleButtonGroup size="small" {...control}>
            <ToggleButton
              className={dynamicCx(dynamicClasses.acceptConfigurationApprovalButton)}
              sx={{ columnGap: '4px' }}
              value={EPaymentConfigurationStatus.APPROVED}
            >
              <PaymentServiceApprovalStatusBadge status={EPaymentConfigurationStatus.APPROVED} />
              Approved
            </ToggleButton>
            <ToggleButton sx={{ columnGap: '4px' }} value={EPaymentConfigurationStatus.DENIED}>
              <PaymentServiceApprovalStatusBadge status={EPaymentConfigurationStatus.DENIED} />
              Denied
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      }
    >
      <ListItemText primary={<ConfigurationApprovalRowText config={props.config} />} />
      {pendingApprovalStatus && (
        <ConfirmationDialog
          {...getConfirmationDialogInfo(pendingApprovalStatus)}
          open={true}
          loading={loading}
          onClose={closeApprovalStatusConfirmation}
          onCancel={closeApprovalStatusConfirmation}
          onConfirm={confirmApprovalStatusAction}
        />
      )}
    </ListItem>
  )
}

interface IConfigurationApprovalRowTextProps {
  config: IPaymentServiceConfiguration
}
function ConfigurationApprovalRowText(props: IConfigurationApprovalRowTextProps) {
  const { cx, classes } = useStyles()

  return (
    <div className={cx(classes.listItemText)}>
      <Typography variant="subtitle1" color="text.primary">
        {props.config.merchantName || '-'}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" className={cx(classes.merchantIdText)}>
        <small>{props.config.merchantId}</small>
      </Typography>
    </div>
  )
}

function getConfirmationDialogInfo(newStatus: EPaymentConfigurationStatus | null) {
  switch (newStatus) {
    case EPaymentConfigurationStatus.DENIED:
      return {
        title: `Configuration Denial`,
        text: 'Are you sure you want to deny this configuration?',
      }
    case EPaymentConfigurationStatus.APPROVED:
      return {
        title: `Configuration Approval`,
        text: 'Are you sure you want to approve this configuration?',
      }
    case EPaymentConfigurationStatus.PENDING_APPROVAL:
    default:
      return {
        title: `Configuration Pending Approval`,
        text: `Are you sure you want to set this configuration to pending approval?`,
      }
  }
}

const useStyles = makeStyles()(() => ({
  listItem: {
    padding: '4px',
    marginBottom: '16px',
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 'calc(100% - 250px)',
  },
  merchantIdText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const useDynamicStyles = makeStyles<{ isMiddleAction: boolean }>()((theme, params) => ({
  acceptConfigurationApprovalButton: {
    borderRadius: params.isMiddleAction ? '0' : 'inherit',
  },
}))
