import MenuItem from '@mui/material/MenuItem'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material'
import { ReactNode, useState } from 'react'
import { IFormOptionEntry } from '@/models'

const StyledSelect = styled(MuiSelect)<MuiSelectProps>(({ theme, error }) => ({
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.border['200']}`,
  height: '38px',
  width: '100%',
  borderRadius: theme.decoration.border.radius.s,
  boxShadow: theme.decoration.shadow.default,
  '& .MuiSelect-select ': {
    padding: 0,
  },
  padding: '8px',
  fontSize: '14px',
}))

interface ISelectProps extends MuiSelectProps {
  error?: boolean
  onChange: (TBasicType) => void
  options?: IFormOptionEntry[]
  showPlaceholderInMenu?: boolean
}
export function Select({ showPlaceholderInMenu = true, ...props }: ISelectProps) {
  const { options, ...otherProps } = props

  const [isAllSelected, setIsAllSelected] = useState(
    props.multiple &&
      areEqualLists(
        options?.map((opt) => opt.value),
        props.value,
      ),
  )

  const handleChange = (newValue) => {
    const selectedAll =
      Array.isArray(newValue) && Array.isArray(options) && newValue.includes('select-all')

    if (selectedAll && !isAllSelected) {
      props.onChange(options.map((option) => option.value))
      setIsAllSelected(true)
    } else if (selectedAll && isAllSelected) {
      props.onChange([])
      setIsAllSelected(false)
    } else {
      props.onChange(newValue)
      handleSingleChange(newValue)
    }
  }

  const handleSingleChange = (newValue) => {
    if (
      props.multiple &&
      areEqualLists(
        options?.map((opt) => opt.value),
        newValue,
      )
    ) {
      setIsAllSelected(true)
    } else {
      setIsAllSelected(false)
    }
  }

  return (
    <StyledSelect
      {...otherProps}
      displayEmpty
      renderValue={(selected) =>
        renderSelectedValue({ selected, placeholder: props.placeholder, options: props.options })
      }
      onChange={(evt) => {
        handleChange(evt.target.value)
      }}
    >
      {showPlaceholderInMenu && (
        <MenuItem disabled value="">
          <em>{props.placeholder}</em>
        </MenuItem>
      )}
      {props.multiple && options && (
        <MenuItem value="select-all">
          <Checkbox checked={isAllSelected} />
          Select All
        </MenuItem>
      )}
      {options?.map((entry) => (
        <MenuItem key={entry.value} value={entry.value}>
          {props.multiple && <Checkbox checked={isValueSelected({ values: props.value, entry })} />}
          {entry.label}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

const isValueSelected = ({ values, entry }) => {
  return values.includes(entry.value)
}

const renderSelectedValue = ({ selected, placeholder, options }) => {
  const isArray = Array.isArray(selected)
  if ((isArray && !selected.length) || !selected) return <div>{placeholder}</div>
  if (!isArray) return <div>{getLabel(selected as string | number, options) as ReactNode}</div>

  const labels = selected.map((option) => getLabel(option, options))
  return <div>{labels.join(', ')}</div>
}

function getLabel(value: string | number, options?: IFormOptionEntry[]) {
  if (!options) return value
  return options.find((opt) => opt.value === value)?.label || value
}

function areEqualLists(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
  if (arr1.length !== arr2.length) return false
  return [...arr1].sort().join(',') === [...arr2].sort().join(',')
}
