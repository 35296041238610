import { useRecoilState } from 'recoil'
import { paymentServiceState } from '@/state/paymentServiceDetail'
import { EventConstants } from '@/constants'
import { TPaymentService } from '@/models'
import { useAppConfig } from '@/hooks/useAppConfig'

export function usePaymentServiceAllowedOrigins() {
  const [pServiceState, setPaymentServiceState] = useRecoilState(paymentServiceState)
  const { config } = useAppConfig()

  const PaymentServiceConfiguration = config.factories.paymentServiceConfigurations
  const EventBus = config.factories.eventBus

  const deleteAllowedOrigin = async (paymentService: TPaymentService, origin: string) => {
    setPaymentServiceState({ ...pServiceState, error: null, loading: true })
    try {
      const allowedOrigins = paymentService.allowedOrigins || []
      const result = await PaymentServiceConfiguration().update({
        provider: paymentService.provider,
        allowedOrigins: allowedOrigins.filter((o) => o !== origin),
      })

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: result,
      })

      setPaymentServiceState({
        data: { ...pServiceState.data, ...result } as TPaymentService,
        error: null,
        loading: false,
        updatingSetting: false,
      })

      return { data: result }
    } catch (e) {
      setPaymentServiceState({ ...pServiceState, error: e, loading: false, updatingSetting: false })
      return { error: e }
    }
  }

  const addAllowedOrigin = async (paymentService: TPaymentService, origin: string) => {
    setPaymentServiceState({ ...pServiceState, error: null, loading: true })
    try {
      const allowedOrigins = paymentService.allowedOrigins || []
      const result = await PaymentServiceConfiguration().update({
        provider: paymentService.provider,
        allowedOrigins: [...allowedOrigins, origin.trim()],
      })

      EventBus().publish(EventConstants.PAYMENT_SERVICE_UPDATED, {
        paymentService: result,
      })

      setPaymentServiceState({
        data: { ...pServiceState.data, ...result } as TPaymentService,
        error: null,
        loading: false,
        updatingSetting: false,
      })

      return { data: result }
    } catch (e) {
      setPaymentServiceState({ ...pServiceState, error: e, loading: false, updatingSetting: false })
      return { error: e }
    }
  }

  return {
    state: pServiceState,
    actions: {
      deleteAllowedOrigin,
      addAllowedOrigin,
    },
  }
}
