import { Button, CardActions, CardContent } from '@mui/material'
import { Divider } from '@/components/Divider'
import { LoadingButton } from '@mui/lab'
import { Fields } from '@/components/forms/Form'
import { makeStyles } from '@/theme'
import { TFormik } from '@/components/forms/Form/FormFields'
import { TFormFieldOrFormFieldList } from '@/helpers/formBuilder'

interface IBaseFormProps<T> {
  formikInstance: TFormik<T>
  onCancel: () => void
  formFields: TFormFieldOrFormFieldList[]
}

export function BaseForm<T>(props: IBaseFormProps<T>) {
  const { classes, cx } = useStyles()

  return (
    <form onSubmit={props.formikInstance.handleSubmit}>
      <CardContent className={cx(classes.content)}>
        <Fields fields={props.formFields} formik={props.formikInstance} />
      </CardContent>
      <Divider />
      <CardActions className={cx(classes.actions)}>
        <Button
          type={'button'}
          onClick={props.onCancel}
          disabled={props.formikInstance.isSubmitting}
        >
          Cancel
        </Button>
        <LoadingButton
          variant={'contained'}
          type={'submit'}
          loading={props.formikInstance.isSubmitting}
        >
          Save
        </LoadingButton>
      </CardActions>
    </form>
  )
}

const useStyles = makeStyles()(() => ({
  content: {
    padding: '24px',
  },
  actions: {
    height: '80px',
    padding: '24px',
    justifyContent: 'space-between',
  },
}))
