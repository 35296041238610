import { IFormField } from '@/models'
import set from 'lodash/set'
import * as yup from 'yup'

export type TFormFieldOrFormFieldList = IFormField | IFormField[]

interface INestedField {
  [key: string]: yup.ObjectShape
}

export function withoutHiddenFields(formFields: TFormFieldOrFormFieldList[]) {
  return formFields.reduce((acc: TFormFieldOrFormFieldList[], fieldOrList) => {
    if (Array.isArray(fieldOrList)) {
      return [...acc, fieldOrList.filter((field) => !field.hideOnConnect)]
    } else if (!fieldOrList.hideOnConnect) {
      return [...acc, fieldOrList]
    } else {
      return acc
    }
  }, [])
}

export function buildSchemaObject(formFields: TFormFieldOrFormFieldList[]) {
  const nestedFields: INestedField = {}
  const fields = formFields.reduce((acc, fieldOrList) => {
    if (Array.isArray(fieldOrList)) {
      return {
        ...acc,
        ...buildSchemaObject(fieldOrList),
      }
    }

    if (isMultiNestedField(fieldOrList.name))
      throw new Error(`${fieldOrList.name}: Multi nested fields are not supported.`)

    if (isNestedField(fieldOrList.name)) {
      set(nestedFields, fieldOrList.name, fieldOrList.schema)
      return acc
    }

    return set(acc, fieldOrList.name, fieldOrList.schema)
  }, {})

  return {
    ...fields,
    ...getObjectSchemas(nestedFields),
  }
}

function getObjectSchemas(nestedFields: INestedField) {
  return Object.entries(nestedFields).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: yup.object(value),
    }),
    {},
  )
}

const isNestedField = (fieldName: string) => fieldName.includes('.')
const isMultiNestedField = (fieldName: string) => fieldName.split('.').length > 2
