import { useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Button, Typography } from '@mui/material'
import { LocalAtm as LocalAtmIcon } from '@mui/icons-material'
import { makeStyles } from '@/theme'
import { EFormFieldType, IAsyncRequestResult, IPaymentServiceConfiguration } from '@/models'
import { buildSchemaObject } from '@/helpers/formBuilder'
import { getFieldErrors } from '@/helpers/errors'
import { toDollars } from '@/helpers/currency'
import { useToast } from '@/hooks/useToast'
import { FormModal } from '@/components/FormModal'
import { BaseForm } from '@/components/forms/Form'

interface IIncrementOverrideProps {
  incrementOptionValues: number[]
  initialIncrement?: number
  handleIncrementChange: (
    increment: number | null,
  ) => Promise<IAsyncRequestResult<IPaymentServiceConfiguration>>
  refresh: () => void
}

export function IncrementOverride(props: IIncrementOverrideProps) {
  const { incrementOptionValues, initialIncrement, handleIncrementChange, refresh } = props

  const { cx, classes } = useStyles()
  const toast = useToast()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const formFields = getFormFields({
    incrementOptionValues,
    initialIncrement,
  })

  const validationSchema = yup.object(buildSchemaObject(formFields))

  const formik = useFormik<{ increment: number | null }>({
    initialValues: {
      increment:
        initialIncrement && incrementOptionValues.includes(initialIncrement)
          ? initialIncrement
          : null,
    },
    validationSchema,
    onSubmit: async (values) => {
      return handleIncrementChange(values.increment)
        .then((result) => {
          if (result.error) {
            result.error.errors && formik.setErrors(getFieldErrors(result.error))
            toast.showMessage('Failed to update increment', 'error')
          } else {
            toast.showMessage('Increment updated', 'success')
            closeModal()
            refresh()
          }
        })
        .catch((e) => {
          if (!e?.message) toast.showMessage('Failed to update increment', 'error')
          toast.showMessage(e.message, 'error')
        })
    },
  })

  return (
    <>
      {isModalOpen && (
        <FormModal title={'Increment override'} onClose={closeModal}>
          <BaseForm formikInstance={formik} onCancel={closeModal} formFields={formFields} />
        </FormModal>
      )}
      <Button className={cx(classes.incrementButton)} onClick={openModal}>
        <LocalAtmIcon />
        <Typography variant="subtitle1" color="text.primary">
          <small>Increment</small>
        </Typography>
      </Button>
    </>
  )
}

const getFormFields = ({ incrementOptionValues, initialIncrement }) => {
  return [
    {
      label: initialIncrement
        ? `This will override the increment ($${toDollars(initialIncrement)})`
        : '',
      placeholder: 'Select an increment',
      type: EFormFieldType.OPTIONS,
      name: 'increment',
      multiple: false,
      required: false,
      schema: yup.number().positive().integer().nullable(),
      options: incrementOptionValues.map((value) => ({ label: `$${toDollars(value)}`, value })),
    },
  ]
}

const useStyles = makeStyles()(() => ({
  incrementButton: {
    height: '40px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 0,
    borderRadius: '4px 0 0 4px',
    padding: '7px',
  },
}))
