import { Box, CircularProgress, Drawer, Fab, IconButton, List, Typography } from '@mui/material'
import { makeStyles } from '@/theme'
import { useConfigurationApprovalsList } from '@/hooks/useConfigurationApprovalsList'
import { IPaymentServiceConfiguration, Provider } from '@/models'
import { useEffect, useMemo, useState } from 'react'
import { EmptyPlaceholder } from '@/components/EmptyPlaceholder'
import { ConfigurationFilters } from '@/components/ConfigurationApprovalsDrawer/ConfigurationFilters'
import { LoadingButton } from '@mui/lab'
import { ConfigurationApprovalRow } from '@/components/ConfigurationApprovalsDrawer/ConfigurationApprovalRow'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'

interface IConfigurationApprovalsDrawerProps {
  provider?: string
}

export function ConfigurationApprovalsDrawer(props: IConfigurationApprovalsDrawerProps) {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const { cx, classes } = useStyles()

  const {
    state: { data: stateConfigurations, loading, filters, hasNextPage },
    actions,
  } = useConfigurationApprovalsList(props.provider)

  const configurations = useMemo(() => {
    if (!filters?.query) return stateConfigurations

    const query: string = filters.query?.toLowerCase()

    return stateConfigurations.filter(
      (config) =>
        config.merchantId.toLowerCase().includes(query) ||
        config.merchantName?.toLowerCase().includes(query),
    )
  }, [stateConfigurations, filters])

  useEffect(() => {
    void actions.loadNextPage()
  }, [filters?.status])

  const noResults = !loading && !configurations.length
  const initialLoading = loading && !configurations.length
  const reachedEndOfList = !hasNextPage && configurations.length > 0
  const displayLoadMore = hasNextPage && !filters?.query

  const drawer = (
    <Box sx={{ minWidth: { xs: '80vw', md: '690px' }, padding: 4 }} role="presentation">
      <div className={cx(classes.header)}>
        <Typography variant="h2" color="text.primary">
          <span>Configuration Approvals</span>
          <IconButton onClick={actions.refresh}>
            <RefreshRoundedIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Typography>

        <IconButton onClick={() => setDrawerOpen(false)}>
          <CloseRoundedIcon sx={{ fontSize: '24px' }} />
        </IconButton>
      </div>

      <ConfigurationFilters provider={props.provider} />
      <div className={cx(classes.resultsContainer)}>
        {initialLoading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {configurations.length > 0 && (
          <List>
            {configurations.map((config: IPaymentServiceConfiguration) => (
              <ConfigurationApprovalRow
                config={config}
                hasIncrementOverrides={hasIncrementOverrides(props.provider || '')}
                key={config.id}
                refresh={actions.refresh}
              />
            ))}
          </List>
        )}
        {noResults && <EmptyPlaceholder hideIcon={true} title={''} description={'No results'} />}
      </div>
      <div className={cx(classes.footerContainer)}>
        {displayLoadMore && (
          <LoadingButton
            variant={'contained'}
            type={'button'}
            onClick={() => actions.loadNextPage()}
            loading={loading}
          >
            Load more
          </LoadingButton>
        )}
        {reachedEndOfList && (
          <Typography variant="subtitle2" color="text.secondary">
            You've reached the end of the list
          </Typography>
        )}
      </div>
    </Box>
  )

  return (
    <>
      <Fab
        variant="extended"
        size="small"
        color="primary"
        onClick={() => setDrawerOpen(true)}
        sx={{ position: 'absolute', right: 16, top: 16 }}
      >
        Configuration Approvals
      </Fab>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        anchor={'right'}
        onClose={() => setDrawerOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

const hasIncrementOverrides = (provider: string) =>
  ([Provider.PAYAUTH] as string[]).includes(provider)

const useStyles = makeStyles()(() => ({
  listItemActions: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  denyButton: {
    color: 'red',
  },
  resultsContainer: {
    marginTop: '16px',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
}))
